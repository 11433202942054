<template>
  <div>
    <div class="card">
      <Toolbar class="p-mb-4">
        <template #start>
          <Button
            label="Menüeintrag hinzufügen"
            icon="pi pi-plus"
            class="p-button-success p-mr-2"
            @click="openNew"
          />
          <Button
            label="Menüeintrag löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="p-mr-2 p-d-inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="view_menu"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
        responsiveLayout="scroll"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div
            class="
              table-header
              p-d-flex p-flex-column p-flex-md-row p-jc-md-between
            "
          >
            <h5 class="p-mb-2 p-m-md-0 p-as-md-center">Menüeinträge</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="suchen..."
              />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="icon" header="Icon" :sortable="false">
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.icon"
              :icon="slotProps.data.icon"
              class="p-button-rounded p-button-primary p-mr-2"
            />
          </template>
        </Column>
        <Column field="label" header="Bezeichnung" :sortable="true"></Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              @click="editIO(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteIO(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="ioDialog"
      :style="{ width: '450px' }"
      header="Eintrag bearbeiten"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="p-field p-col">
        <div class="p-field p-text-center">
          <span :class="ioDialogData.label + ' mif-5x'"></span>
        </div>
      </div>
      <div class="p-field">
        <label for="label">Bezeichnung</label>
        <Textarea
          id="desc"
          v-model="ioDialogData.label"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIoDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <span style="font-family: LogFileFont; color: yellow">{{
            ioDialogData.label
          }}</span>
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIoDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIosDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIosDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedIOs"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import helpers from "@/helpers";
import { FilterMatchMode } from "primevue/api";
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
// import socketioService from "@/services/socketioService.js";

export default {
  name: "view_menu",
  data() {
    return {
      loading: false,
      ioDialog: false,
      deleteIoDialog: false,
      deleteIosDialog: false,
      ioDialogData: {},
      config_io: null,
      view_menu: null,
      selectedIOs: null,
      filters: {},
      submitted: false,
      intval_Value: null,
      elementIndex: -1,
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    this.loading = true;
    this.getParameter();
    this.registerSocketIoEvents();
  },
  beforeUnmount() {
    this.unregisterSocketIoEvents();
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        // group: {},
      };
      this.elementIndex = -1;
    },
    getParameterConfigIO() {
      SocketioService.getViewMenu((response) => {
        // this.config_io = response;
        this.view_menu = response;
        this.loading = false;
        // this.io_department = response.types_department;
        // this.io_types = response.types_io;
        // this.io_unit = response.types_unit;
        // this.io_server = response.types_server;
        // this.io_icons = response.types_icons;
      });
    },
    getParameter() {
      this.getParameterConfigIO();
    },
    registerSocketIoEvents() {
      SocketioService.socket.on("set_config_io_type_icons_result", (data) => {
        if (data !== null) {
          this.getParameter();
          // document not changed
          if (
            data.acknowledged &&
            data.matchedCount > 0 &&
            data.upsertedCount === 0 &&
            data.modifiedCount === 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "Eintrag unverändert",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , keine Änderung notwendig ",
              life: 3000,
            });
            // document updated
          } else if (
            data.acknowledged &&
            data.matchedCount > 0 &&
            data.upsertedCount === 0 &&
            data.modifiedCount > 0
          ) {
            this.$toast.add({
              severity: "success",
              summary: "Eintrag geändert",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , alle Änderungen wurden gespeichert ",
              life: 3000,
            });
            // document deleted
          } else if (data.acknowledged && data.deletedCount > 0) {
            this.$toast.add({
              severity: "success",
              summary: "Eintrag gelöscht",
              detail: data.deletedCount + " Einträge erfolgreich gelöscht",
              life: 3000,
            });
            // document created
          } else if (data.acknowledged && data.insertedId !== null) {
            this.$toast.add({
              severity: "success",
              summary: "Eintrag erstellt",
              detail:
                "Eintrag mit Name [" +
                data.insertedId.toString() +
                "] erfolgreich erstellt",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Eintrag nicht durchgeführt",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , Erstellt: " +
                data.upsertedCount +
                " [" +
                data.upsertedId +
                "]",
              life: 3000,
            });
          }
        }
      });
    },
    unregisterSocketIoEvents() {
      SocketioService.socket.removeAllListeners(
        "set_config_io_type_icons_result"
      );
    },
    openNew() {
      this.resetDialogData();
      this.submitted = false;
      this.ioDialog = true;
    },
    hideDialog() {
      this.ioDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      this.getParameter();
    },
    saveIO() {
      this.submitted = true;

      if (this.ioDialogData.label.trim()) {
        this.ioDialogData.icon = this.ioDialogData.label;
        // let _index = helpers.findIndexByName(this.io_type, this.ioDialogData.value);
        if (this.elementIndex >= 0) {
          console.log(this.ioDialogData);
          this.io_type[this.elementIndex] = this.ioDialogData;
          SocketioService.setConfigIOType(this.io_type, "types_icons");
        } else {
          let _size = Object.keys(this.io_type).length;
          this.io_type[_size] = this.ioDialogData;
          SocketioService.setConfigIOType(this.io_type, "types_icons");
        }
        // clearInterval(this.intval_Value);
        this.ioDialog = false;
        this.resetDialogData();
      }
    },
    editIO(ioData) {
      // this.ioDialogData = { ...ioData };
      this.getParameter();
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.elementIndex = helpers.findIndexByName(this.io_type, _obj.value);
      this.ioDialogData = _obj;
      this.ioDialog = true;
    },
    confirmDeleteIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteIoDialog = true;
    },
    deleteIO() {
      let _index = helpers.findIndexByName(
        this.io_type,
        this.ioDialogData.value
      );
      if (_index >= 0) {
        this.io_type.splice(_index, 1);
        SocketioService.setConfigIOType(this.io_type, "types_icons");
        // SocketioService.removeUser(this.system_user[_index]);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Updated",
        //   life: 3000,
        // });
      } else {
        // this.product.id = this.createId();
        // this.product.code = this.createId();
        // this.product.image = "product-placeholder.svg";
        // this.products.push(this.product);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Created",
        //   life: 3000,
        // });
      }
      this.deleteIoDialog = false;
      this.resetDialogData();
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: "Product Deleted",
      //   life: 3000,
      // });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteIosDialog = true;
    },
    deleteSelectedIOs() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteIosDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.var-icon {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}
.card {
  background: #1a2226;
}
::v-deep(.table-header) {
  background: #1a2226 !important;
}
::v-deep(.p-datatable .p-datatable-thead tr th) {
  background: #1a2226 !important;
}
::v-deep(.p-datatable .p-rowgroup-header) {
  background: #1a2226 !important;
}
::v-deep(.p-datatable tr) {
  background: #222d32 !important;
}
</style>